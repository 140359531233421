import React, { useState } from "react";
import "../app.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Home from "../pages/home";
import { SideNav, TopBar } from "../layout";
import { Layout } from "antd";
import withUser from "../hooks/with_auth";
import paths from "./paths";
import SalePage from "../pages/sales";
import AppoinmentPage from "../pages/appointments";
import ProudctPage from "../pages/products";
import ServicePage from "../pages/services";
import TherapistPage from "../pages/therapists";
import CheckInPage from "../pages/check_in_out";
import ServicePackagePage from "../pages/service_packages";
import ServiceStockItemPage from "../pages/service_stock_items";
import ServiceStockItemUsagePage from "../pages/service_stock_item_usages";
import ProductStockItemPage from "../pages/product_stock_items";
import DailySaleReport from "../pages/reports/daily_sale_report";
import TherapistSaleReport from "../pages/reports/therapist_sale_report";
import MemberPage from "../pages/member/members";
import OfferCategoryPage from "../pages/offer/offer_category";
import OfferPage from "../pages/offer/offers";
import MemberDetail from "../pages/member/member_detail";
import NotificationPage from "../pages/notifications";
import TherapistLeavePage from "../pages/therapist_leaves";
import ServiceDetailPage from "../pages/service_detail/service_detail";
import MemberImport from "../pages/member/member_import";
import ClinicDetail from "../pages/clinic_detail";
import BankPage from "../pages/tool/banks";
import NewSalePage from "../pages/newsale";
import NewCheckout from "../pages/new_checkout";
import CheckInDetail from "../pages/checkin_detail";
import ServiceRoomPage from "../pages/service_rooms";
import ServiceRoomDetail from "../pages/service_room_detail";
import SaleDetailPage from "../pages/sale_detail";
import WalletPage from "../pages/wallet/accounts";
import TranscationPage from "../pages/wallet/transactions";
import ServiceTypeCategoryPage from "../pages/service_type_categories";
import TherapistsDetail from "../pages/therapists_detail";
import TreatmentReport from "../pages/reports/treatment_report";
import TopTreatmentReport from "../pages/reports/top_treatment_report";
import TopPractitionerReport from "../pages/reports/top_practitioner_report";
import TopMemberReport from "../pages/reports/top_member_report";
import Helper from "../pages/helper";
import User from "../pages/user/users";
import ServiceConsentFormPage from "../pages/service_form/consent_forms";
import ServiceRecordFormPage from "../pages/service_form/record_forms";
import CustomerBIDashboard from "../bi-dashboard/customer";
import ServiceBIDashboard from "../bi-dashboard/service";
import SellerReport from "../pages/reports/seller_report";
import SaleChannelPage from "../pages/sale_channel/sale_channels";

const { Header, Content, Footer } = Layout;
function PRoutes(props: any) {
  // console.log(props, "user props");
  const Navigate = useNavigate();
  const location = useLocation();
  const currentMenuItem =
    location && location.pathname ? location.pathname : paths.home;
  return (
    <div
      className="App"
      //style={{ overflowX: "hidden" }}
    >
      <Layout style={{ minHeight: "100vh" }} hasSider>
        <SideNav
          onMenuClik={(val: any) => {
            Navigate(`${val.key}`);
          }}
          currentMenuItem={currentMenuItem}
        />
        <Layout>
          <Header>
            <TopBar />
          </Header>
          <Content>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path={paths.home} element={<Home />} />
              <Route path={paths.sales} element={<SalePage />} />
              <Route path={paths.appointments} element={<AppoinmentPage />} />
              <Route path={paths.checkIns} element={<CheckInPage />} />
              <Route path={paths.services} element={<ServicePage />} />
              <Route
                path={paths.service_detail}
                element={<ServiceDetailPage />}
              />
              <Route
                path={paths.service_packages}
                element={<ServicePackagePage />}
              />
              <Route path={paths.products} element={<ProudctPage />} />
              <Route path={paths.therapists} element={<TherapistPage />} />
              <Route
                path={paths.therapist_detail}
                element={<TherapistsDetail />}
              />
              <Route path={paths.leaves} element={<TherapistLeavePage />} />
              <Route path={paths.report} element={<DailySaleReport />} />
              <Route
                path={paths.treatment_report}
                element={<TreatmentReport />}
              />
              <Route
                path={paths.practitioner_treatment_report}
                element={<TreatmentReport />}
              />
              <Route
                path={paths.top_treatment_report}
                element={<TopTreatmentReport />}
              />
              <Route
                path={paths.top_treatment_report}
                element={<TopTreatmentReport />}
              />
              <Route
                path={paths.top_practitioner_report}
                element={<TopPractitionerReport />}
              />
              <Route
                path={paths.top_member_report}
                element={<TopMemberReport />}
              />
              <Route path={paths.seller_report} element={<SellerReport />} />
              <Route path={paths.daily_sales} element={<DailySaleReport />} />
              <Route
                path={paths.therapist_sales}
                element={<TherapistSaleReport />}
              />
              <Route
                path={paths.product_stock_items}
                element={<ProductStockItemPage />}
              />
              <Route
                path={paths.service_stock_items}
                element={<ServiceStockItemPage />}
              />
              <Route path={paths.helper} element={<Helper />} />
              <Route
                path={paths.service_stock_usages}
                element={<ServiceStockItemUsagePage />}
              />
              <Route path={paths.members} element={<MemberPage />} />
              <Route path={paths.offers} element={<OfferPage />} />
              <Route
                path={paths.offer_categories}
                element={<OfferCategoryPage />}
              />
              <Route path={paths.member_detail} element={<MemberDetail />} />
              <Route
                path={paths.notifications}
                element={<NotificationPage />}
              />
              <Route path={paths.memberImport} element={<MemberImport />} />
              <Route path={paths.clinic} element={<ClinicDetail />} />
              <Route path={paths.banks} element={<BankPage />} />
              <Route path={paths.newSale} element={<NewSalePage />} />
              <Route path={paths.newCheckout} element={<NewCheckout />} />
              <Route path={paths.checkIn} element={<CheckInDetail />} />
              <Route path={paths.serviceRooms} element={<ServiceRoomPage />} />
              <Route
                path={paths.serviceRoomDetail}
                element={<ServiceRoomDetail />}
              />
              <Route path={paths.saleDetail} element={<SaleDetailPage />} />
              <Route path={paths.wallets} element={<WalletPage />} />
              <Route path={paths.transactions} element={<TranscationPage />} />
              <Route
                path={paths.service_categories}
                element={<ServiceTypeCategoryPage />}
              />
              <Route path={paths.user} element={<User />} />

              <Route
                path={paths.service_consent_froms}
                element={<ServiceConsentFormPage />}
              />
              <Route
                path={paths.service_record_froms}
                element={<ServiceRecordFormPage />}
              />
              {/*
               <Route path={paths.test_p} element={<TestPage />} /> */}
              <Route
                path={paths.customerBehaviourDashboard}
                element={<CustomerBIDashboard />}
              />
              <Route
                path={paths.serviceBehaviourDashborad}
                element={<ServiceBIDashboard />}
              />
              <Route path={paths.sale_channel} element={<SaleChannelPage />} />
            </Routes>
          </Content>
          <Footer style={{ padding: "20px 0" }}>
            <span>&copy; {new Date().getFullYear()} Greate Time</span>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
}
export default withUser(PRoutes);
// export default PRoutes;
